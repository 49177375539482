<template>
  <div>
    <div v-if="sportangebot">
      <v-app-bar app flat elevate-on-scroll :color="unsaved_changes ? 'blue-grey lighten-3' : 'white'" :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 56px;' : ''"> 
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn x-large rounded icon to="/verein/sportangebote/bearbeiten" exact>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col align-self="center">
            <h2 class="text-truncate">Sportangebot erstellen</h2>
          </v-col>
          <v-col cols="auto" align-self="center" v-if="$vuetify.breakpoint.mdAndUp">
            <v-switch v-model="sportangebot.active" @change="update" hide-details inset color="primary" :label="sportangebot.active ? 'Aktiviert' : 'Deaktiviert'"></v-switch>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn large rounded color="success" :disabled="!unsaved_changes" @click="save">
              <font-awesome-icon class="mr-2" size="lg" icon="fa-solid fa-floppy-disk" />
              Speichern
            </v-btn>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn large rounded text color="red" @click="remove">
              <v-icon>mdi-delete</v-icon>
              Löschen
            </v-btn>
          </v-col>
        </v-row>
        <template slot="extension" v-if="unsaved_changes">
          <v-spacer></v-spacer><font-awesome-icon icon="fa-solid fa-file-exclamation" class="primary--text mr-2" size="xl" /><h3 class="primary--text text-uppercase">Du hast ungespeicherte Änderungen</h3><v-spacer></v-spacer>
        </template>
      </v-app-bar>
    </div>
    <v-container v-if="sportangebot">
      <v-row>
        <v-col cols="12">
          <v-card light class="rounded-xl pa-4" flat>
            <v-row>
              <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
                <v-btn 
                  v-if="sportangebot.active"
                  rounded
                  color="error" 
                  class="mt-3 mr-2"
                  @click="
                    sportangebot.active = false
                    update()
                  "
                >
                  <font-awesome-icon icon="fa-solid fa-ban" class="mr-2" size="lg" /> Angebot deaktivieren
                </v-btn> 
                <v-btn 
                  v-if="!sportangebot.active"
                  rounded
                  color="success" 
                  class="mt-3 mr-2"
                  @click="
                    sportangebot.active = true
                    update()
                  "
                >
                  <font-awesome-icon icon="fa-solid fa-check" size="lg" class="mr-2" /> Angebot aktivieren
                </v-btn>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  filled
                  rounded
                  label="Name"
                  v-model="sportangebot.name"
                  hide-details
                  @change="update"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  filled
                  rounded
                  label="Beschreibung"
                  v-model="sportangebot.beschreibung"
                  @change="update"
                  :hint="sportangebot.beschreibung ? 'Anzahl Zeichen: '+sportangebot.beschreibung.length+'/600' : ''"
                  persistent-hint
                ></v-textarea>
              </v-col>
              <v-col cols="12" lg="4">
                <v-select
                  :items="sportarten.data"
                  item-value="id"
                  item-text="name"
                  offset-y
                  filled
                  rounded
                  hide-details
                  label="Sportart"
                  v-model="sportangebot.sportart"
                  @change="update"
                ></v-select>
              </v-col>
              <v-col cols="12" lg="4">
                <v-select
                  :items="altersgruppen"
                  offset-y
                  filled
                  rounded
                  hide-details
                  multiple
                  label="Altersgruppe(n)"
                  v-model="sportangebot.altersgruppen"
                  @change="update"
                ></v-select>
              </v-col>
              <v-col cols="12" lg="4">
                <v-select
                  :items="geschlecht"
                  offset-y
                  filled
                  rounded
                  hide-details
                  label="Geschlecht"
                  prepend-inner-icon="mdi-gender-male-female"
                  v-model="sportangebot.geschlecht"
                  @change="update"
                ></v-select>
              </v-col>
              <v-col cols="12" lg="4">
                <v-select
                  :items="teilnehmerzahl"
                  offset-y
                  filled
                  rounded
                  hide-details
                  label="Maximale Teilnehmerzahl"
                  prepend-inner-icon="mdi-account-group"
                  v-model="sportangebot.teilnehmerzahl"
                  @change="update"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="center">
        <v-col cols="12">
          <v-card light class="rounded-xl pa-4" flat>
            <v-row>
              <v-col cols="12">
                <h3>Medien</h3>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="4"
                align-self="center"
                v-for="(media, index) in sportangebot.fotos"
                :key="index"
              >
                <v-card
                  color="secondary"
                  class="rounded-xl pa-0 ma-4"
                  style="min-height: 200px;"
                >
                  <v-row justify="center" style="height: 100%;">
                    <v-col cols="6" align-self="center" v-if="media.img">
                      <v-img :src="media.img" width="100%"></v-img>
                    </v-col>
                    <v-col cols="8" align-self="center">
                      <v-btn
                        block
                        rounded
                        :text="!media.img"
                        :color="media.img ? 'primary' : 'white'"
                        @click.stop="media.dialog = true"
                      >
                        <v-icon>
                          {{ media.img ? 'mdi-change' : 'mdi-upload' }}
                        </v-icon>
                        {{ media.img ? 'BILD ÄNDERN' : 'BILD HOCHLADEN' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
                <v-dialog
                  v-model="media.dialog"
                  :max-width="$vuetify.breakpoint.mdAndUp ? '1200' : 'none'"
                  :overlay-opacity="
                    $vuetify.breakpoint.mdAndUp ? 0.85 : undefined
                  "
                  :hide-overlay="$vuetify.breakpoint.mdAndUp ? false : true"
                  :transition="
                    $vuetify.breakpoint.mdAndUp
                      ? 'dialog-transition'
                      : 'dialog-bottom-transition'
                  "
                  :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true"
                  :width="$vuetify.breakpoint.mdAndUp ? 'auto' : '100vw'"
                  content-class="rounded-xl"
                >
                  <v-card class="rounded-xl">
                    <v-toolbar color="primary" dark>
                      <v-tabs
                        v-model="tab"
                        fixed-tabs
                        dark
                      >
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab href="#tab-1">
                          Bild hochladen
                        </v-tab>

                        <v-tab href="#tab-2">
                          Bilderdatenbank
                        </v-tab>
                      </v-tabs>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="closeDialog(index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>

                    </v-toolbar>
                    <v-tabs-items v-model="tab">
                      <v-tab-item value="tab-1">
                        <v-card-text>
                          <v-row justify="center" class="my-4">
                            <v-col :cols="media.imageData ? 12 : 10">
                              <v-image-input
                                v-model="media.imageData"
                                :image-quality="0.85"
                                clearable
                                accept="image/*"
                                :hideActions="media.imageData ? false : true"
                                :imageWidth="800"
                                :imageMinScaling="'cover'"
                                :imageHeight="600"
                                :full-width="true"
                                image-format="png"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              class="text-center"
                              v-if="!media.imageData"
                            >
                              Für eine gute Darstellung wähle bitte ein Bild mit
                              mindestens 600 x 800 Pixeln.
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions right>
                          <v-btn
                            color="primary"
                            right
                            rounded
                            :loading="media.uploading"
                            @click="upload(index)"
                          >
                            Speichern
                          </v-btn>
                          <v-btn
                            color="secondary"
                            right
                            rounded
                            @click="closeDialog(index)"
                          >
                            Abbrechen
                          </v-btn>
                        </v-card-actions>
                      </v-tab-item>
                      <v-tab-item value="tab-2">
                        <v-row class="pa-4" justify="center" v-if="sportangebot.sportart">
                          <v-col cols="12" class="text-center">
                            <v-btn class="mx-2" rounded color="primary" :outlined="fotos_toggle != 'Sportart'" @click="fotos_toggle = 'Sportart'">
                              {{sportangebot.sportart.name || sportangebot.sportart}}
                            </v-btn>
                            <v-btn class="mx-2" rounded color="primary" :outlined="fotos_toggle != 'Alle'" @click="fotos_toggle = 'Alle'">
                              Alle
                            </v-btn>
                          </v-col>
                          <v-col cols="6" md="4" v-for="(foto, index) in fotos" :key="'foto'+index">
                            <v-card class="rounded-xl" @click="media.img = foto.img; media.dialog = false; update()">
                              <v-img height="200" :src="foto.img" />
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row class="pa-4" justify="center" v-else>
                          <v-col cols="6" md="4" v-for="(foto, index) in alle_fotos" :key="'foto'+index">
                            <v-card class="rounded-xl" @click="media.img = foto.img; media.dialog = false; update()">
                              <v-img :src="foto.img" />
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-btn
                  rounded
                  color="primary"
                  @click="
                    sportangebot.fotos.push({
                      imageData: '',
                      titelbild: false,
                      uploading: false,
                      dialog: true,
                      img: '',
                    })
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                  bild Hinzufügen
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="center">
        <v-col cols="12">
            <v-card light class="rounded-xl pa-4" flat>
            <v-row>
                <v-col cols="12">
                <h3>Zeitraum</h3>
                </v-col>
                <v-col cols="12">
                <v-select
                    filled
                    rounded
                    label="Wiederholung"
                    :items="repeat"
                    v-model="sportangebot.wiederholung"
                    hide-details
                    @change="init_wiederholung()"
                ></v-select>
                </v-col>
                <v-col
                cols="12"
                v-if="sportangebot.wiederholung == 'Einmaliger Termin'"
                >
                <v-row v-if="sportangebot.termin">
                    <v-col cols="12">
                          <v-menu
                            v-model="menu.einmalig.datum"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="parseDate(sportangebot.termin.datum)"
                                    label="Datum"
                                    prepend-inner-icon="mdi-calendar"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="sportangebot.termin.datum"
                                locale="de"
                                first-day-of-week="1"
                                @input="menu.einmalig.datum = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" lg>
                          <v-menu
                            v-model="menu.einmalig.beginn"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="sportangebot.termin.beginn"
                                    label="Beginn"
                                    prepend-inner-icon="mdi-clock"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="sportangebot.termin.beginn"
                                format="24hr"
                                @input="menu.einmalig.beginn = false"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col
                    cols="auto"
                    v-if="$vuetify.breakpoint.lgAndUp"
                    align-self="center"
                    >
                        bis
                    </v-col>
                    <v-col cols="12" lg>
                        <v-menu
                            v-model="menu.einmalig.ende"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="sportangebot.termin.ende"
                                    label="Ende"
                                    prepend-inner-icon="mdi-clock"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="sportangebot.termin.ende"
                                format="24hr"
                                @input="menu.einmalig.ende = false"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                </v-col>
                <v-col
                cols="12"
                v-if="sportangebot.wiederholung == 'Mehrere Termine'"
                >
                <v-row
                    v-for="(termin, index) in sportangebot.termine"
                    :key="index"
                >
                    <v-col cols="12" class="font-weight-bold">
                    <v-row justify="space-between">
                        <v-col cols="auto">Termin {{ index + 1 }}:</v-col>
                        <v-col cols="auto" v-if="sportangebot.termine.length > 2">
                            <v-btn
                                icon
                                @click="removeTermin(index)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu.mehrere[index].datum"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="parseDate(termin.datum)"
                                    label="Datum"
                                    prepend-inner-icon="mdi-calendar"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="termin.datum"
                                locale="de"
                                first-day-of-week="1"
                                @input="menu.mehrere[index].datum = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" lg>
                        <v-menu
                            v-model="menu.mehrere[index].beginn"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="termin.beginn"
                                    label="Beginn"
                                    prepend-inner-icon="mdi-clock"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="termin.beginn"
                                format="24hr"
                                @input="menu.mehrere[index].beginn = false"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col
                    cols="auto"
                    v-if="$vuetify.breakpoint.lgAndUp"
                    align-self="center"
                    >
                        bis
                    </v-col>
                    <v-col cols="12" lg>
                        <v-menu
                            v-model="menu.mehrere[index].ende"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="termin.ende"
                                    label="Ende"
                                    prepend-inner-icon="mdi-clock"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="termin.ende"
                                format="24hr"
                                @input="menu.mehrere[index].ende = false"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="auto">
                    <v-btn
                        color="primary"
                        rounded
                        @click="
                            sportangebot.termine.push({
                                datum: '',
                                beginn: '',
                                ende: '',
                            })
                            menu.mehrere.push({
                                datum: false,
                                beginn: false,
                                ende: false
                            })
                        "
                    >
                        <v-icon>mdi-plus</v-icon>
                        Termin hinzufügen
                    </v-btn>
                    </v-col>
                </v-row>
                </v-col>
                <v-col
                  cols="12"
                  v-if="sportangebot.wiederholung == 'Mehrtägiger Termin'"
                >
                <v-row
                    v-for="(termin, index) in sportangebot.termine"
                    :key="index"
                >
                    <v-col cols="12">
                        <v-menu
                            v-model="menu.mehrere[index].datum"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="parseDate(termin.datum)"
                                    label="Datum"
                                    prepend-inner-icon="mdi-calendar"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="termin.datum"
                                locale="de"
                                first-day-of-week="1"
                                @input="menu.mehrere[index].datum = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu.mehrere[index].bisdatum"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="parseDate(termin.bisdatum)"
                                    label="Bis Datum"
                                    prepend-inner-icon="mdi-calendar"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="termin.bisdatum"
                                locale="de"
                                first-day-of-week="1"
                                @input="menu.mehrere[index].bisdatum = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" lg>
                        <v-menu
                            v-model="menu.mehrere[index].beginn"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="termin.beginn"
                                    label="Beginn"
                                    prepend-inner-icon="mdi-clock"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="termin.beginn"
                                format="24hr"
                                @input="menu.mehrere[index].beginn = false"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col
                    cols="auto"
                    v-if="$vuetify.breakpoint.lgAndUp"
                    align-self="center"
                    >
                        bis
                    </v-col>
                    <v-col cols="12" lg>
                        <v-menu
                            v-model="menu.mehrere[index].ende"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="termin.ende"
                                    label="Ende"
                                    prepend-inner-icon="mdi-clock"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="termin.ende"
                                format="24hr"
                                @input="menu.mehrere[index].ende = false"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="auto">
                    <v-btn
                        color="primary"
                        rounded
                        @click="
                            sportangebot.termine.push({
                                datum: '',
                                beginn: '',
                                ende: '',
                            })
                            menu.mehrere.push({
                                datum: false,
                                beginn: false,
                                ende: false
                            })
                        "
                    >
                        <v-icon>mdi-plus</v-icon>
                        Termin hinzufügen
                    </v-btn>
                    </v-col>
                </v-row>
                </v-col>
                <v-col
                cols="12"
                v-if="sportangebot.wiederholung == 'Regelmäßige Termine'"
                >
                <v-row
                    v-for="(termin, index) in sportangebot.zeitraum"
                    :key="index"
                >
                    <v-col cols="12" class="font-weight-bold">
                    <v-row justify="space-between">
                        <v-col cols="auto">Termin {{ index + 1 }}:</v-col>
                        <v-col cols="auto" v-if="sportangebot.zeitraum.length > 1">
                            <v-btn
                                icon
                                @click="removeZeitraum(index)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    </v-col>
                    <v-col cols="12">
                    <v-select
                        filled
                        rounded
                        hide-details
                        label="Wochentag"
                        v-model="termin.wochentag"
                        :items="[
                        'Montag',
                        'Dienstag',
                        'Mittwoch',
                        'Donnerstag',
                        'Freitag',
                        'Samstag',
                        'Sonntag',
                        ]"
                        prepend-inner-icon="mdi-calendar"
                        
                    ></v-select>
                    </v-col>
                    <v-col cols="12" v-if="menu.regelmaessig.length > 0">
                        <v-menu
                            v-model="menu.regelmaessig[index].vondatum"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="parseDate(termin.datum.von)"
                                    label="Von Datum"
                                    prepend-inner-icon="mdi-calendar"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="termin.datum.von"
                                locale="de"
                                first-day-of-week="1"
                                @input="menu.regelmaessig[index].vondatum = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" v-if="menu.regelmaessig.length > 0">
                        <v-menu
                            v-model="menu.regelmaessig[index].bisdatum"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="parseDate(termin.datum.bis)"
                                    label="Bis Datum"
                                    prepend-inner-icon="mdi-calendar"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="termin.datum.bis"
                                locale="de"
                                first-day-of-week="1"
                                @input="menu.regelmaessig[index].bisdatum = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" lg v-if="menu.regelmaessig.length > 0">
                        <v-menu
                            v-model="menu.regelmaessig[index].beginn"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="termin.uhrzeit.beginn"
                                    label="Beginn"
                                    prepend-inner-icon="mdi-clock"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="termin.uhrzeit.beginn"
                                format="24hr"
                                @input="menu.regelmaessig[index].beginn = false"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col
                    cols="auto"
                    v-if="$vuetify.breakpoint.lgAndUp"
                    align-self="center"
                    >
                    bis
                    </v-col>
                    <v-col cols="12" lg v-if="menu.regelmaessig.length > 0">
                        <v-menu
                            v-model="menu.regelmaessig[index].ende"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="termin.uhrzeit.ende"
                                    label="Ende"
                                    prepend-inner-icon="mdi-clock"
                                    filled
                                    rounded
                                    hide-details
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="termin.uhrzeit.ende"
                                format="24hr"
                                @input="menu.regelmaessig[index].ende = false"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-btn
                            color="primary"
                            rounded
                            @click="
                                sportangebot.zeitraum.push({
                                    wochentag: '',
                                    datum: {
                                        von: '',
                                        bis: '',
                                    },
                                    uhrzeit: {
                                        beginn: '',
                                        ende: '',
                                    }
                                })
                                menu.regelmaessig.push({
                                    vondatum: false,
                                    bisdatum: false,
                                    beginn: false,
                                    ende: false
                                })
                            "
                        >
                            <v-icon>mdi-plus</v-icon>
                            Termin hinzufügen
                        </v-btn>
                    </v-col>
                </v-row>
                </v-col>
            </v-row>
            </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="center">
        <v-col cols="12">
          <v-card light class="rounded-xl pa-4" flat>
            <v-row>
              <v-col cols="12">
                <h3>Veranstaltungsort</h3>
              </v-col>
              <v-col cols="12" v-if="sportangebot.veranstaltungsort">
                <v-text-field
                  filled
                  rounded
                  label="Name des Veranstaltungsortes"
                  prepend-inner-icon="mdi-office-building-marker"
                  v-model="sportangebot.veranstaltungsort.name"
                  hide-details
                  @change="update"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="sportangebot.veranstaltungsort">
                <v-text-field
                  filled
                  rounded
                  label="Adresse Veranstaltungsortes"
                  prepend-inner-icon="mdi-map-marker"
                  v-model="sportangebot.veranstaltungsort.adresse"
                  hide-details
                  @change="update"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="4" v-if="sportangebot.veranstaltungsort">
                <v-text-field
                  filled
                  rounded
                  label="Postleitzahl"
                  prepend-inner-icon="mdi-map-marker"
                  v-model="sportangebot.veranstaltungsort.plz"
                  hide-details
                  @change="update"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="8" v-if="sportangebot.veranstaltungsort">
                <v-text-field
                  filled
                  rounded
                  label="Ort"
                  prepend-inner-icon="mdi-home-map-marker"
                  v-model="sportangebot.veranstaltungsort.ort"
                  hide-details
                  @change="update"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="center">
        <v-col
          cols="12"
          v-for="(ansprechpartner, index) in sportangebot.ansprechpartner"
          :key="index"
        >
          <v-card light class="rounded-xl pa-4" flat>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <h3>
                      {{ index > 0 ? index + 1 + '. ' : '' }}Ansprechpartner
                    </h3>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      icon
                      color="danger"
                      v-if="sportangebot.ansprechpartner.length > 1"
                      @click="removeAnsprechpartner(index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  rounded
                  filled
                  v-model="ansprechpartner.vorname"
                  label="Vorame"
                  hide-details
                  @change="update"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  rounded
                  filled
                  v-model="ansprechpartner.nachname"
                  label="Nachame"
                  hide-details
                  @change="update"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  rounded
                  filled
                  v-model="ansprechpartner.funktion"
                  label="Funktion (z.B. Trainer | optional)"
                  hide-details
                  @change="update"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  rounded
                  filled
                  v-model="ansprechpartner.email"
                  label="E-Mail"
                  hide-details
                  @change="update"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  rounded
                  filled
                  v-model="ansprechpartner.telefon"
                  label="Telefon (optional)"
                  hide-details
                  @change="update"
                ></v-text-field>
                <v-checkbox color="primary" hide-details v-model="ansprechpartner.telefonpublic" label="Telefonnummer darf öffentlich angezeigt werden" @change="update()"></v-checkbox>
                <v-checkbox v-if="ansprechpartner.telefonpublic" color="primary" hide-details v-model="ansprechpartner.whatsapp" @change="update()" label="Telefonnummer kann per WhatsApp kontaktiert werden"></v-checkbox>  
              </v-col>
              <v-col cols="12" lg="6">
                <span class="ml-3" v-if="emailVerified(ansprechpartner)"><font-awesome-icon class="green--text mr-2" icon="fa-solid fa-check-double" />E-Mail wurde verifiziert.</span>
              </v-col>
              <v-col
                cols="12"
                v-if="index + 1 == sportangebot.ansprechpartner.length"
                class="text-center"
              >
                <v-btn
                  rounded
                  color="success"
                  @click="
                    sportangebot.ansprechpartner.push({
                        vorname: '',
                        nachname: '',
                        telefon: '',
                        telefonpublic: false,
                        whatsapp: false,
                        email: '',
                        emailvalid: false,
                        funktion: '',
                    })
                    update
                  "
                >
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  Hinzufügen
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="center">
        <v-col cols="12">
          <v-card light class="rounded-xl pa-4" flat>
            <v-row>
              <v-col cols="12">
                <h3>
                  Material, das Teilnehmer mitbringen müssen
                </h3>
              </v-col>
              <v-col cols="12">
                <v-row
                    justify="center"
                    v-for="(material, index) in sportangebot.material"
                    :key="index"
                >
                    <v-col cols="2" align-self="center" class="primary--text text-center">
                        <v-icon color="primary" v-if="material.iconset == 'mdi'" large>mdi-{{material.icon}}</v-icon>
                        <font-awesome-icon v-if="material.iconset == 'fa'" size="lg" :icon="material.icon" />   
                    </v-col>
                    <v-col align-self="center">
                        <v-text-field
                            filled
                            rounded
                            label="Bezeichnung"
                            v-model="material.name"
                            hide-details
                            @change="update()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="auto" align-self="center">
                        <v-btn 
                          icon 
                          @click="
                            removeMaterial(index)
                            update()
                          "
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn
                        color="primary"
                        rounded
                        @click="addMaterial = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Hinzufügen
                    </v-btn>
                </v-col>
                <v-dialog content-class="rounded-xl" v-model="addMaterial" max-width="700">
                    <v-card class="rounded-xl">
                        <v-row justify="space-around" class="pa-5">
                            <v-col cols="12" class="text-center">
                                <h2>Wähle eine Vorlage aus</h2>
                            </v-col>
                            <v-col v-for="m in material" :key="m.name" cols="6" md="4">
                                <v-card 
                                    color="#b6b4c8" 
                                    hover 
                                    class="rounded-xl primary--text text-center pa-4 h-100" 
                                    @click="
                                        sportangebot.material.push(m)
                                        addMaterial = false
                                        update()
                                    ">
                                    <v-icon x-large color="primary" v-if="m.iconset == 'mdi'">mdi-{{m.icon}}</v-icon><br v-if="m.iconset == 'mdi'">
                                    <font-awesome-icon v-if="m.iconset == 'fa'" size="xl" :icon="m.icon" /><br v-if="m.iconset == 'fa'">
                                    <h3 class="my-2">{{m.name}}</h3>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog> 
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore'
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage'
import router from '../../../router'
import md5 from 'md5'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true
})

export default {
  name: 'sportangebot',
  data() {
    return {
      addMaterial: false,
      sportangebot: '',
      vdoc: '',
      tab: 0,
      fotos_toggle: 'Sportart',
      unsaved_changes: false,
      altersgruppen: [
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
      ],
      teilnehmerzahl: [],
      repeat: ['Einmaliger Termin', 'Mehrere Termine', 'Mehrtägiger Termin', 'Regelmäßige Termine'],
      geschlecht: ['Nur für Jungen', 'Nur für Mädchen', 'Für Alle'],
      menu: {
        einmalig: {
          datum: false,
          beginn: false,
          ende: false  
        },
        regelmaessig: [
            {
                vondatum: false,
                bisdatum: false,
                beginn: false,
                ende: false
            }
        ],
        mehrere: [
            {
                datum: false,
                bisdatum: false,
                beginn: false,
                ende: false
            },
            {
                datum: false,
                bisdatum: false,
                beginn: false,
                ende: false
            }
        ]
      },
      material: [
          {
              name: 'Sportschuhe',
              icon: 'shoe-sneaker',
              iconset: 'mdi'
          },
          {
              name: 'Hallenschuhe',
              icon: 'shoe-sneaker',
              iconset: 'mdi'
          },
          {
              name: 'Laufschuhe',
              icon: 'shoe-sneaker',
              iconset: 'mdi'
          },
          {
              name: 'Fussballschuhe',
              icon: 'shoe-cleat',
              iconset: 'mdi'
          },
          {
              name: 'Sportshirt',
              icon: 'fa-light fa-shirt',
              iconset: 'fa'
          },
          {
              name: 'Sportjacke',
              icon: 'fa-solid fa-shirt-long-sleeve',
              iconset: 'fa'
          },
          {
              name: 'Pullover',
              icon: 'fa-solid fa-shirt-long-sleeve',
              iconset: 'fa'
          },
          {
              name: 'Sporthose',
              icon: 'fa-light fa-clothes-hanger',
              iconset: 'fa'
          },
          {
              name: 'Schwimmkleidung',
              icon: 'fa-light fa-clothes-hanger',
              iconset: 'fa'
          },
          {
              name: 'Handtuch',
              icon: 'fa-light fa-rectangle-vertical',
              iconset: 'fa'
          },
          {
              name: 'Maske',
              icon: 'fa-light fa-mask-face',
              iconset: 'fa'
          },
          {
              name: 'Trinkflasche',
              icon: 'fa-regular fa-droplet',
              iconset: 'fa'
          },
          {
              name: 'Tischtennis-Schläger',
              icon: 'fa-light fa-table-tennis-paddle-ball',
              iconset: 'fa'
          },
          {
              name: 'Tennis-Schläger',
              icon: 'fa-light fa-racquet',
              iconset: 'fa'
          },
          {
              name: 'Badminton-Schläger',
              icon: 'fa-light fa-badminton',
              iconset: 'fa'
          },
          {
              name: 'Angelsachen',
              icon: 'fa-light fa-fishing-rod',
              iconset: 'fa'
          },
          {
              name: 'Campingstuhl',
              icon: 'fa-light fa-chair',
              iconset: 'fa'
          },
          {
              name: 'Weitere',
              icon: 'fa-light fa-plus',
              iconset: 'fa'
          }
      ],
    }
  },
  mounted() {
    this.setSportangebot()
    this.teilnehmerzahl = []
    for (var i = 1; i <= 100; i++) {
      this.teilnehmerzahl.push(i)
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
      db: 'db',
    }),
    fotos(){
      var fotos = []
      var sportarten = []
      if(this.fotos_toggle == 'Sportart'){
        if(typeof this.sportangebot.sportart === 'object'){
          sportarten = this.sportarten.all.filter(item => item.name == this.sportangebot.sportart.name)
        }
        else {
          sportarten = this.sportarten.all.filter(item => item.name == this.sportangebot.sportart)
        }
      }
      else {
        sportarten = this.sportarten.all
      }
      sportarten.forEach(item => {
        if(item.fotos){
          item.fotos.forEach(foto => {
            fotos.push(foto)
          })
        }
      })
      return fotos
    },
    alle_fotos(){
      var fotos = []
      this.sportarten.all.forEach(item => {
        if(item.fotos){
          item.fotos.forEach(foto => {
            fotos.push(foto)
          })
        }
      })
      return fotos
    }
  },
  methods: {
    init_wiederholung(){
      if(this.sportangebot?.wiederholung == 'Einmaliger Termin'){
        this.sportangebot.termin = {
          datum: '',
          beginn: '',
          ende: ''
        }
        this.menu.einmalig = {
          datum: false,
          beginn: false,
          ende: false  
        }
      }
      if(this.sportangebot?.wiederholung == 'Mehrere Termine'){
        this.sportangebot.termine = [
          {
            datum: '',
            beginn: '',
            ende: ''
          },
          {
            datum: '',
            beginn: '',
            ende: ''
          }
        ]
        this.menu.mehrere = [
          {
            datum: false,
            beginn: false,
            ende: false  
          },
          {
            datum: false,
            beginn: false,
            ende: false  
          }
        ]
      }
      if(this.sportangebot?.wiederholung == 'Mehrtägiger Termin'){
        this.sportangebot.termine = [
          {
            datum: '',
            bisdatum: '',
            beginn: '',
            ende: ''
          },
        ]
        this.menu.mehrere = [
          {
            datum: false,
            bisdatum: false,
            beginn: false,
            ende: false  
          },
        ]
      }
      if(this.sportangebot?.wiederholung == 'Regelmäßige Termine'){
        this.sportangebot.zeitraum = [
          {
            wochentag: '',
            datum: {
              von: '',
              bis: '',
            },
            uhrzeit: {
              beginn: '',
              ende: ''
            }
          },
        ]
        this.menu.regelmaessig = [
          {
            vondatum: false,
            bisdatum: false,
            beginn: false,
            ende: false  
          },
        ]
      }
    },
    parseDate(date){
        if(date){
            var datum = date.split('-')
            if(datum.length == 3){
                return datum[2]+'.'+datum[1]+'.'+datum[0]
            }
        }
        return date
    },
    emailVerified(ansprechpartner){
      if(this.sportangebot.mailingliste?.includes(md5(ansprechpartner.email))){
        return true
      }
      return false
    },
    update(){
      this.unsaved_changes = true
    },
    save() {
      var angebot = this.sportangebot
      angebot.verein = this.sportangebot.verein?.id || this.sportangebot.verein
      angebot.sportart =
        this.sportangebot.sportart?.id || this.sportangebot.sportart
      try {
        updateDoc(this.vdoc, angebot)
        Toast.fire({
          icon: 'success',
          title: 'Erfolgreich gespeichert!'
        })
        this.unsaved_changes = false
      }
      catch(error){
        Toast.fire({
          icon: 'error',
          title: 'Beim speichern ist ein Fehler aufgetreten!'
        })
      }

    },
    async remove() {
      const deletion = await deleteDoc(
        doc(this.db, 'Sportangebote', this.sportangebot.id),
      )
      router.push('/admin/sportangebote')
    },
    setSportangebot() {
        this.sportangebot = {
            name: '',
            verein: this.user.data.verein.id,
            beschreibung: '',
            sportart: '',
            veranstaltungsort: {
                name: '',
                adresse: '',
                plz: '',
                ort: '',
            },
            altersgruppen: [],
            geschlecht: [],
            teilnehmerzahl: '',
            termin: { datum: '', beginn: '', ende: '' },
            termine: [{ datum: '', beginn: '', ende: '' }, { datum: '', beginn: '', ende: '' }],
            zeitraum: [
                {
                    wochentag: '',
                    datum: { von: '', bis: '' },
                    uhrzeit: { beginn: '', ende: '' },
                },
            ],
            wiederholung: 'Einmaliger Termin',
            material: [],
            ansprechpartner: [
            {
                vorname: '',
                nachname: '',
                funktion: '',
                telefon: '',
                telefonpublic: false,
                whatsapp: false,
                email: '',
                emailvalid: false
            },
            ],
            fotos: [],
            sonstiges: '',
            created_at: '',
            created: false,
        }
    },
    removeTermin(id) {
      var neue_termine = []
      this.sportangebot.termine.forEach((termin, index) => {
        if (index != id) {
          neue_termine.push(termin)
        }
      })
      this.sportangebot.termine = neue_termine
    },
    removeZeitraum(id) {
      var neuer_zeitraum = []
      this.sportangebot.zeitraum.forEach((termin, index) => {
        if (index != id) {
          neuer_zeitraum.push(termin)
        }
      })
      this.sportangebot.zeitraum = neuer_zeitraum
    },
    removeAnsprechpartner(id) {
      var neue_ansprechpartner = []
      this.sportangebot.ansprechpartner.forEach((person, index) => {
        if (index != id) {
          neue_ansprechpartner.push(person)
        }
      })
      this.sportangebot.ansprechpartner = neue_ansprechpartner
    },
    removeMaterial(id) {
      var neues_material = []
      this.sportangebot.material.forEach((material, index) => {
        if (index != id) {
          neues_material.push(material)
        }
      })
      this.sportangebot.material = neues_material
      this.update()
    },
    closeDialog(index) {
      this.sportangebot.fotos[index].dialog = false
      this.sportangebot.fotos[index].uploading = false
      this.sportangebot.fotos[index].imageData = ''
    },
    async createSportangebot(){
        this.creating = true
        this.success = false
        try {
            const angebotRef = await addDoc(collection(this.db, 'Sportangebote'), {
                name: this.sportangebot.name,
                verein: this.sportangebot.verein,
                vereinsname: this.verein.name || '',
                beschreibung: this.sportangebot.beschreibung,
                sportart: this.sportangebot.sportart,
                veranstaltungsort: this.sportangebot.veranstaltungsort,
                altersgruppen: this.sportangebot.altersgruppen,
                geschlecht: this.sportangebot.geschlecht,
                teilnehmerzahl: this.sportangebot.teilnehmerzahl,
                termin: this.sportangebot.termin,
                termine: this.sportangebot.termine,
                zeitraum: this.sportangebot.zeitraum,
                wiederholung: this.sportangebot.wiederholung,
                material: this.sportangebot.material,
                ansprechpartner: this.sportangebot.ansprechpartner,
                fotos: this.sportangebot.fotos,
                created_at: serverTimestamp(),
                approved: false,
                active: false
            })
            this.success = true
            this.creating = false
        } catch(e) {
            console.log(e)
            this.error = "Fehler beim einreichen des Sportangebots: " + e
            this.creating = false
        }
    },
    async upload(index) {
      const storage = getStorage();
      this.sportangebot.fotos[index].uploading = true
      if (this.sportangebot.fotos[index].imageData) {
        const image = this.sportangebot.fotos[index].imageData.replace('data:image/png;base64,', '')
        const storageRef = ref(storage, '/sportangebote/'+this.sportangebot.id+'-'+index+'.jpg');
        uploadString(storageRef, image, 'base64').then(snapshot => {
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            this.sportangebot.fotos[index].img = downloadURL;
            this.sportangebot.fotos[index].uploading = false
            this.sportangebot.fotos[index].dialog = false
          });
        })
        this.update()
      }
    },
    getDate(date){
      if(date instanceof Timestamp){
        var datum = date.toDate()
        return datum.getDate().toString().padStart(2, '0')+'.'+datum.getMonth().toString().padStart(2, '0')+'.'+datum.getFullYear()
      }
      return date
    }
  },
}
</script>